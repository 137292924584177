import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { clsx } from "clsx";

import type { AiColors, PosProduct } from "@repo/types";
import { T, useT } from "@repo/transifex";
import { ItemLayout } from "~/components/ItemLayout";
import { useAppContext, useAppDispatch } from "~/providers/app";
import { basketActions } from "~/providers/store/basket";
import { modalVariantsActions } from "~/providers/store/modal-variants";
import { useSendWebEvent } from "~/hooks";
import { useCurrentTill } from "~/hooks/queries";

function getBadgeByLimit(
  limit: number | null,
  t: ReturnType<typeof useT>,
): {
  disabled: boolean;
  badge: string | undefined;
} {
  if (typeof limit !== "number") return { disabled: false, badge: undefined };

  if (limit > 0) {
    const left = limit;
    return {
      disabled: false,
      badge: t("{left} Left", { left: left > 9 ? "9+" : left }),
    };
  }

  return { disabled: true, badge: t("Limit reached") };
}

function getBadgeByDates(
  deadlineDate: number | null,
  onsetDate: number | null,
  offset: number,
  t: ReturnType<typeof useT>,
): {
  disabled: boolean;
  badge: string | undefined;
} {
  if (!deadlineDate && !onsetDate) return { disabled: false, badge: undefined };

  let disabled = false;
  let badge;

  if (onsetDate) {
    const isToday = moment.utc(onsetDate * 1000).isSame(moment().utc(), "day");
    const isOnSetPassed =
      isToday && onsetDate < moment().add(offset, "minutes").unix();

    if (isToday && !isOnSetPassed) {
      disabled = true;
      badge = t("Available after {date}", {
        date: moment.unix(onsetDate).add(-offset, "minutes").format("HH:mm"),
      });
    }
  }

  if (deadlineDate) {
    const isToday = moment
      .utc(deadlineDate * 1000)
      .isSame(moment().utc(), "day");
    const isDeadlinePassed =
      isToday && deadlineDate < moment().add(offset, "minutes").unix();
    switch (true) {
      // if there is a starting time and the deadline has not yet expired
      case Boolean(badge):
        break;
      // if the deadline has expired
      case !isToday:
      case isDeadlinePassed:
        disabled = true;
        badge = t("Too late to order");
        break;
      // if the deadline has not yet expired
      default:
        disabled = false;
        badge = t("Buy before {date}", {
          date: moment
            .unix(deadlineDate)
            .add(-offset, "minutes")
            .format("HH:mm"),
        });
    }
  }

  return { disabled, badge };
}

export const ProductItem = ({
  product,
  offset,
  disabled,
  unitSystem,
}: {
  product: PosProduct;
  offset: number;
  disabled: boolean;
  unitSystem?: string;
}) => {
  const t = useT();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const sendWebEvent = useSendWebEvent();

  const {
    hardware: {
      scales: { scale },
    },
  } = useAppContext();

  const { data: till, status } = useCurrentTill();

  const { name, displayPrice, limit, deadline, onset } = product;

  const [datesInfo, setDatesInfo] = useState(() =>
    getBadgeByDates(deadline, onset, offset, t),
  );
  const { disabled: disabledByTime, badge: badgeTime } = datesInfo;

  const { disabled: disabledByLimit, badge: badgeLimit } = getBadgeByLimit(
    limit,
    t,
  );

  useEffect(() => {
    if (!deadline && !onset) return;
    const intervalId = setInterval(() => {
      setDatesInfo(getBadgeByDates(deadline, onset, offset, t));
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [deadline, onset, offset, t]);

  const hasVariants = product.options.length > 0;
  const isWeightedProduct = product.unitSystem !== "piece";

  const unitSystemLabel = unitSystem
    ? {
      "1-gram": t("pr. 1 g"),
      "100-grams": t("pr. 100 g"),
      kilogram: t("pr. 1 kg"),
    }[unitSystem]
    : "";

  if (status !== "success") return null;

  const hasColor = till.config.hasProductColors;

  return (
    <ItemLayout
      badge={badgeTime ?? badgeLimit}
      className={clsx(
        "flex size-full flex-1 flex-col justify-end gap-2 bg-ai-gray-primary p-4 text-left text-lg text-ai-gray-secondary",
        hasColor &&
        typeof product.color !== "undefined" &&
        getColorClassNames(product.color),
      )}
      disabled={disabledByTime || disabledByLimit || disabled}
      onClick={() => {
        dispatch({ type: "COUNTDOWN_RESET" });

        switch (true) {
          case isWeightedProduct: {
            if (scale) {
              const scaleData = "model" in scale ? scale.scale : scale;
              sendWebEvent({ type: "SCALE_CONNECT_REQUEST", payload: scaleData });
            }
            navigate(`${pathname}/weight-product/${product.id}`);
            break;
          }
          case hasVariants:
            modalVariantsActions.init({
              productId: product.id,
              options: product.options,
            });
            navigate(`/product/${product.id}/variants`);
            break;
          default:
            basketActions.addRegular(product.id);
        }
      }}
    >
      <span className="line-clamp-2 text-2xl leading-tight">{name}</span>

      {hasVariants ? (
        <span className="text-base opacity-70">
          <T _str="Choose variant" />
        </span>
      ) : (
        <div className="text-base">
          {!till.moduleConfig.hidePrices ? <span>{displayPrice}</span> : null}
          {isWeightedProduct ? (
            <span className="ml-2 opacity-70">{unitSystemLabel ?? ""}</span>
          ) : null}
        </div>
      )}
    </ItemLayout>
  );
};

const getColorClassNames = (color: AiColors) => {
  const colorMap: Record<AiColors, string> = {
    lime: "!bg-ai-lime-primary !text-ai-lime-secondary",
    mint: "!bg-ai-mint-primary !text-ai-mint-secondary",
    teal: "!bg-ai-teal-primary !text-ai-teal-secondary",
    blue: "!bg-ai-blue-primary !text-ai-blue-secondary",
    navy: "!bg-ai-navy-primary !text-ai-navy-secondary",
    purple: "!bg-ai-purple-primary !text-ai-purple-secondary",
    pink: "!bg-ai-pink-primary !text-ai-pink-secondary",
    red: "!bg-ai-red-primary !text-ai-red-secondary",
    brown: "!bg-ai-brown-primary !text-ai-brown-secondary",
    orange: "!bg-ai-orange-primary !text-ai-orange-secondary",
    yellow: "!bg-ai-yellow-primary !text-ai-yellow-secondary",
    gray: "!bg-ai-gray-primary !text-ai-gray-secondary",
  };

  return colorMap[color] || "";
};
